import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

const Header = ({ siteTitle }) => (
  <div className={"pageHeader"}>
    <Link to="/">{siteTitle}</Link>
    <a
      href={"mailto:lsteitle@me.com?Subject=Logan's Portfolio"}
      target={"_top"}
    >
      Contact
    </a>
  </div>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
